import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ViewUser.css';
import axios from 'axios';
import { backUrl } from '../../constants';
import defaultImage from '../Auth/34AD2.jpg';

const Profile = () => {
    const navigate = useNavigate();

    const [userData, setUserData] = useState("");
    const [assetData, setAssetData] = useState([]);
    const [userHistory, setUserHistory] = useState([]);

    const getUserDetails = async () => {
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set the appropriate content type
            };

            const response = await axios.get(backUrl + "auth/getProfileData", { headers })

            setUserData(response.data[0]);
            setAssetData(response.data[0].assetDetails);
        } catch (error) {
            console.log(error);
        };
    };

    const getOwnUserHistory = async () => {
        const token = localStorage.getItem('token');

        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Set the appropriate content type
        };
        try {
            const response = await axios.get(backUrl + "history/getOwnUserHistory", { headers });

            setUserHistory(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const convertToIST = (utcTime) => {
        const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30 in milliseconds
        const istTime = new Date(utcTime.getTime() + istOffset);

        // Extract date, month, year, hours, and minutes
        const date = istTime.getUTCDate();
        const month = istTime.getUTCMonth() + 1; // Month is zero-based, so add 1
        const year = istTime.getUTCFullYear();
        const hours = istTime.getUTCHours();
        const minutes = istTime.getUTCMinutes();

        // Format the IST date and time with leading zeros
        const formattedDate = `${date}/${month}/${year}`;
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return `${formattedDate} ${formattedTime}`;
    };


    useEffect(() => {
        getUserDetails();
        getOwnUserHistory();
    }, []);

    return (
        <>
            <div className='container-fluid'>

                <div className='row justify-content-center view_row'>

                    <div className='col-lg-6 col-sm-12 text-center'>
                        <div className='profile_card'>
                            {userData?.profilePhoto ? (
                                <img src={userData.profilePhoto} alt="" className="profile_image" />
                            ) : (
                                <img src={defaultImage} alt="" className="profile_image" />
                            )}
                            <div className='row'>
                                <p className='profile_name'>{userData?.fullname || ""}</p>
                            </div>
                            <div className='row'>
                                <p className='designation'>{userData?.designation || ""}</p>
                            </div>
                            <h4 className='text-start basic_details_heading'>Basic Details</h4>

                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Email
                                </div>
                                <div className='col-8'>
                                    {userData?.email || ""}
                                </div>
                            </div>

                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Emp Id
                                </div>
                                <div className='col-8'>
                                    {userData?.empCode || ""}
                                </div>
                            </div>

                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Mobile No
                                </div>
                                <div className='col-8'>
                                    {userData?.mobileNo || ""}
                                </div>
                            </div>

                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Assets Assigned
                                </div>
                                <div className='col-8'>
                                    {assetData?.length}
                                </div>
                            </div>

                            <h4 className='text-start basic_details_heading mt-3'>Assets Assigned</h4>
                            {assetData.map((item) => (
                                <>
                                    {
                                        item.assetState === 2 && (
                                            <div className='pending_card text-start row align-items-center' key={item._id}>
                                                <div className='col-6'>
                                                    <h6 className='fw-bold pt-2'>{item.assetName}</h6>
                                                    <p className='assigned_p'>{item.brandName}</p>
                                                </div>
                                                <div className='col-6 text-end pe-4'>
                                                    <button className='pendingKaViewButton' onClick={() => navigate(`/viewasset/${item._id}`)}>Accept</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        item.assetState === 4 && (
                                            <div className='assigned_card text-start row align-items-center' key={item._id}>
                                                <div className='col-6'>
                                                    <h6 className='fw-bold pt-2'>{item.assetName}</h6>
                                                    <p className='assigned_p'>{item.brandName}</p>
                                                </div>
                                                <div className='col-6 text-end pe-4'>
                                                    <button className='assetKaViewButton' onClick={() => navigate(`/viewasset/${item._id}`)}>View</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            ))}

                        </div>
                    </div>

                    <div className='col-lg-6 col-sm-12'>



                        <div className='profile_card'>
                            <h4 className='text-start basic_details_heading'>Activities History</h4>
                            <div>
                                <ul className='activity_list'>
                                    {userHistory.map((item) => (
                                        <li className='view_list mt-3' key={item._id}>
                                            <div className='activity_card'>
                                                <h6 className='fw-bold'>{convertToIST(new Date(item.createdAt))}</h6>
                                                {item.activityType === 1 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> added <span className='activityLink'>{item.assetInvovedName}</span> to inventory.</p>
                                                )}
                                                {item.activityType === 2 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> assigned <span className='activityLink'>{item.assetInvovedName}</span> to <span className='activityLink'>{item.userTargetedName}</span>.</p>
                                                )}
                                                {item.activityType === 3 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> denied to recieve <span className='activityLink'>{item.assetInvovedName}</span>.</p>
                                                )}
                                                {item.activityType === 4 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> recieved <span className='activityLink'>{item.assetInvovedName}</span>. [{item.remarks}]</p>
                                                )}
                                                {item.activityType === 7 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> unassigned <span className='activityLink'>{item.assetInvovedName}</span> from <span className='activityLink'>{item.userTargetedName}</span>. [{item.remarks}]</p>
                                                )}
                                                {item.activityType === 8 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> send <span className='activityLink'>{item.assetInvovedName}</span> for maintenance. [{item.remarks}]</p>
                                                )}
                                                {item.activityType === 9 && (
                                                    <p><span className='activityLink'>{item.assetInvovedName}</span> repaired and recieved by <span className='activityLink'>{item.userInvolvedName}</span>.</p>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile