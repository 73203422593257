import React, { useEffect, useState, useRef } from 'react';
import './AllUsers.css';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { backUrl } from '../../constants';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ClearIcon from '@mui/icons-material/Clear';
import { Html5QrcodeScanner } from 'html5-qrcode';


const AllAssets = () => {
  const navigate = useNavigate();

  const columns = [
    {
      accessorKey: 'serialNumber',
      header: "Serial Number",
      size: 20,
    },
    {
      accessorKey: "assetName",
      header: "Name",
      size: 200,
    },
    {
      accessorKey: "assignedToName",
      header: "Assigned To",
      size: 100,
    },
    {
      accessorKey: "brandName",
      header: "Brand Name",
      size: 200,
    },
    {
      accessorKey: "modelNumber",
      header: "Model Number",
      size: 200,
    },
    {
      header: "Action", // Column header for the action buttons
      Cell: ({ row }) => (
        <Button style={{ backgroundColor: "#012970", color: "white" }} onClick={() => viewAsset(row.original._id)}>View</Button>
      ),
      size: 10,
    },
  ];

  const [allAssets, setAllAssets] = useState([]);
  const [scanResult, setScanResult] = useState(null);
  const [isScanQr, setIsScanQr] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const selectedHeaders = ['Serial Number', 'Name', 'Assigned To', 'Brand Name', 'Model Number'];
  const selectedColumns = columns.filter(column => selectedHeaders.includes(column.header));


  const getallAssets = async () => {
    try {
      const response = await axios.get(backUrl + "asset/getAllAssets", {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});

      setAllAssets(response.data);
      setIsScanQr(true);
    } catch (error) {
      console.log(error);
    }
  }

  const filterAssets = (scanningResult) => {
    const filteredData = allAssets.filter(item => item.serialNumber === scanningResult);
    setAllAssets(filteredData);
  }

  const viewAsset = (id) => {
    navigate(`/viewasset/${id}`);
  };

  useEffect(() => {
    getallAssets();

    function handleResize() {
      setIsMobile(window.innerWidth < 768); // Adjust the width threshold as needed
    }

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Initial check when the component mounts
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // This is for qr code scan
  const scanQr = () => {
    const scanner = new Html5QrcodeScanner('reader', {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 5,
    });

    scanner.render(success, error);

    function success(result) {
      scanner.clear();
      setScanResult(result);
      filterAssets(result);
      setIsScanQr(false);
    }

    function error(err) {
      console.log(err);
    }
  }

  const filteredAssets = allAssets.map(asset => {
    const filteredData = {};
    selectedColumns.forEach(column => {
      filteredData[column.accessorKey] = asset[column.accessorKey];
    });
    return filteredData;
  });

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    headers: selectedColumns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = rows => {
    const filteredRows = rows.map(row => {
      const filteredData = {};
      selectedColumns.forEach(column => {
        filteredData[column.accessorKey] = row.original[column.accessorKey];
      });
      return filteredData;
    });

    csvExporter.generateCsv(filteredRows);
  };

  const handleExportData = () => {
    csvExporter.generateCsv(filteredAssets);
  };

  console.log(scanResult);


  return (
    <>
      <div id="reader">
      </div>

      <div className='container-fluid full_div'>
        <div className='row'>
          <h3 className='text-center h3_addAsset'>ASSETS</h3>

          <MaterialReactTable
            columns={columns}
            data={allAssets}
            enableRowSelection
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >
                <Button
                  style={{ backgroundColor: "#012970" }}
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  onClick={handleExportData}
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                >
                  Export All
                </Button>

                <Button
                  style={{
                    backgroundColor: table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
                      ? "#012970"
                      : "#CCCCCC",
                  }}
                  disabled={
                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                  }
                  //only export selected rows
                  onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                >
                  Export Selected
                </Button>

                <Button
                  style={{ backgroundColor: "#012970" }}
                  onClick={() => navigate("/addasset")}
                  startIcon={<AddIcon />}
                  variant="contained"
                >
                  ADD ASSET
                </Button>

                {isMobile && (
                  <>
                  {isScanQr ? (
                    <Button
                      className={isMobile ? 'mobile-hidden' : ''}
                      style={{ backgroundColor: "#012970" }}
                      onClick={scanQr}
                      startIcon={<QrCodeScannerIcon />}
                      variant="contained"
                    >
                      SCAN QR
                    </Button>
                  ) : (
                    <Button
                      className={isMobile ? 'mobile-hidden' : ''}
                      style={{ backgroundColor: "#ff3305" }}
                      onClick={getallAssets}
                      startIcon={<ClearIcon />}
                      variant="contained"
                    >
                      CLEAR
                    </Button>
                  )}
                  </>
                )}
              </Box>
            )}


          />


        </div>
      </div>
    </>
  )
}

export default AllAssets