import React, { useState } from 'react';
import '../Auth/Login.css';
import axios from 'axios';
import { backUrl } from '../../constants';

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [result, setResult] = useState("");

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setResult('');


    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setResult('');


    };

    const submitHandler = async (e) => {
        e.preventDefault();

        // Password length validation
        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters long');
            return;
        } else {
            setPasswordError('');
        }

        // Password match validation
        if (confirmPassword !== password) {
            setConfirmPasswordError('Passwords do not match');
            return;
        } else {
            setConfirmPasswordError('');
        }

        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set the appropriate content type
            };

            const response = await axios.post(backUrl + "auth/changePassword", { password: password }, { headers })

            setResult(response.data);
            setPassword('');
            setConfirmPassword('');

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className='container full_div' style={{ height: "95vh" }}>
                <div className='row justify-content-center'>
                    <h3 className='changePassword pt-3 text-center welcome_text'>Change Password</h3>

                    <div className='col-lg-6 col-sm-12 pt-5'>
                        <label htmlFor="password" className="label">
                            Enter New Password
                            <input name="password" type="password" className="input-field" id="password"
                                placeholder="Enter New Password"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </label>
                        <p className='inputError'>{passwordError}</p>

                        <label htmlFor="cpassword" className="label">
                            Confirm New Password
                            <input name="cpassword" type="password" className="input-field" id="cpassword"
                                placeholder="Confirm New Password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                            />
                        </label>
                        <p className='inputError'>{confirmPasswordError}</p>

                        <button className="btn" type="button" onClick={submitHandler}>SUBMIT</button>

                    </div>

                    <h5 className='resultResponse text-center'>{result}</h5>
                </div>
            </div>
        </>
    )
}

export default ChangePassword