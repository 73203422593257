import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ViewUser.css';
import axios from 'axios';
import { backUrl } from '../../constants';
import Swal from 'sweetalert2';
import { useTollgate } from '../CustomPackages/Tollgate';
import noPicture from './upload cloud vector icon_4279412.png';
import jwt_decode from 'jwt-decode';

const ViewAsset = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const token = localStorage.getItem('token');

    const userData = jwt_decode(localStorage.getItem('token'));

    const headers = {
        'Authorization': `Bearer ${token}`, // Set the appropriate content type
    };

    const [assetData, setAssetData] = useState("");
    const [allUsers, setAllUsers] = useState([]);
    const [assignUser, setAssignUser] = useState("");
    const [inventoryData, setInentoryData] = useState([]);
    const [isThisAdmin, setIsThisAdmin] = useState(false);
    const [currentUser, setCurrentUser] = useState('');
    const [asset_name, setasset_name] = useState('');
    const [serial_number, setserial_number] = useState('');
    const [assignable_status, setassignable_status] = useState('');
    const [brand_name, setbrand_name] = useState('');
    const [model_number, setmodel_number] = useState('');
    const [fields, setfields] = useState([]);
    const [purchased_date, setpurchased_date] = useState('');
    const [warranty_expire, setwarranty_expire] = useState('');
    const [asset_invoice, setasset_invoice] = useState('');
    const [assetother_documents, setassetother_document] = useState('');
    const [asset_photo, setasset_photo] = useState('');
    const [historyData, setHistoryData] = useState([]);
    const [remarks, setRemarks] = useState('');
    const [remarksAsset, setRemarksAsset] = useState('');
    const [remarksMaintenance, setRemarksMaintenance] = useState('');
    const [myData, setMyData] = useState("");


    const chceckAdmin = () => {
        try {
            setMyData(userData);
        } catch (error) {
            console.log(error);
        }
    };

    //To fetch all the details of asset
    const getAssetDetails = async (id) => {
        try {
            const response = await axios.post(backUrl + "asset/getAssetDetails", { assetId: id })

            setAssetData(response.data[0]);
            setasset_name(response.data[0].assetName);
            setserial_number(response.data[0].serialNumber);
            setassignable_status(response.data[0].assetType);
            setbrand_name(response.data[0].brandName);
            setmodel_number(response.data[0].modelNumber);
            setfields(response.data[0].fields);
            setpurchased_date(response.data[0].purchasedDate);
            setwarranty_expire(response.data[0].warrantyExpire);
        } catch (error) {
            console.log(error);
        };
    };

    const getAssetHistory = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set the appropriate content type
            };

            const response = await axios.post(backUrl + 'history/getAssetHistory', { assetId: id }, { headers });

            setHistoryData(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    //To fetch all users' details for assign
    const getAllUsers = async () => {
        try {
            const response = await axios.get(backUrl + "auth/getAllUsersList", { headers });

            setAllUsers(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    //For fetching of inventory details
    const handleChangeOption = (e) => {
        setAssignUser(e.target.value);

        const singleData = allUsers.filter(item => item._id === e.target.value);
        setInentoryData(singleData[0].assetDetails);
    }

    //For assign
    const handleAssign = async () => {

        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Set the appropriate content type
        };

        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
        });

        if (confirmResult.isConfirmed) {
            try {

                const response = await axios.post(backUrl + "asset/assignDevice", {
                    assetId: id,
                    userId: assignUser,
                }, { headers });

                getAssetDetails(id);
                getAssetHistory(id);

                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleBasicDetailsUpdate = async () => {
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set the appropriate content type
            };

            const response = await axios.post(backUrl + "asset/updateAsset1", {
                assetId: id,
                assetName: asset_name,
                serialNumber: serial_number,
                assetType: assignable_status,
            }, { headers });

            getAssetDetails(id);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    const handleBasicDetailsUpdate2 = async () => {
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set the appropriate content type
            };

            const response = await axios.post(backUrl + "asset/updateAsset2", {
                assetId: id,
                brandName: brand_name,
                modelNumber: model_number,
                fields: fields,
            }, { headers });

            getAssetDetails(id);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    // Function to trigger the download
    const handleDownloadClick = async (fileLink) => {
        try {
            const response = await axios({
                method: 'get',
                url: backUrl + 'asset/download',
                responseType: 'blob', // Tell axios to treat the response as a blob
                params: {
                    path: fileLink, // Send the file path as a query parameter
                },
            });

            // Extract the filename from the fileLink
            const filename = fileLink.split('/').pop() || 'downloaded_file';

            // Create a temporary URL for the blob
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create an anchor element for downloading
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // Set the extracted filename
            document.body.appendChild(a);
            a.click();

            // Clean up by revoking the Blob URL
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleSpecificationFieldChange = (index, key, newValue) => {
        const updatedFields = [...fields];
        updatedFields[index][key] = newValue;
        setfields(updatedFields);
    };

    // Handler for removing a field
    const removeField = (index) => {
        const updatedFields = [...fields];

        if (updatedFields.length === 1) {
            return; // Prevent removing when there are no fields
        }

        updatedFields.splice(index, 1);
        setfields(updatedFields);
    };

    // Handler for adding a new field
    const addField = () => {
        const newField = { property: '', value: '' };
        setfields([...fields, newField]);
    };

    const deleteAssetInvoicePhoto = async () => {
        const confirmResult = await Swal.fire({
            title: "Are you sure? Once delete, can't undo.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
        });

        if (confirmResult.isConfirmed) {
            try {
                const token = localStorage.getItem('token');

                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json', // Set the appropriate content type
                };

                const response = await axios.post(backUrl + 'asset/deleteAssetInvoicePhoto', {
                    assetId: id,
                    assetInvoice: assetData.assetInvoice,
                }, { headers });

                getAssetDetails(id)
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const deleteOtherDocumentsPhoto = async () => {
        const confirmResult = await Swal.fire({
            title: "Are you sure? Once delete, can't undo.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
        });

        if (confirmResult.isConfirmed) {
            try {
                const token = localStorage.getItem('token');

                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json', // Set the appropriate content type
                };

                const response = await axios.post(backUrl + 'asset/deleteOtherDocumentsPhoto', {
                    assetId: id,
                    otherDocAsset: assetData.otherDocAsset,
                }, { headers });

                getAssetDetails(id)
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const submitOtherDocuments = async () => {
        const formData = new FormData();

        formData.append('assetId', id);
        formData.append('purchased_date', purchased_date);
        formData.append('warranty_expire', warranty_expire);
        formData.append('asset_invoice', asset_invoice);
        formData.append('assetother_documents', assetother_documents);

        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data', // Set the appropriate content type
            };

            const response = await axios.post(backUrl + 'asset/updateOtherDocuments', formData, { headers });

            console.log(response.data);
            getAssetDetails(id);
            setasset_invoice('');
            setassetother_document('');
        } catch (error) {
            console.log(error);
        }
    }

    const uploadAssetPhoto = async () => {
        const formData = new FormData();

        formData.append('assetId', id);
        formData.append('asset_photo', asset_photo);

        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data', // Set the appropriate content type
            };

            const response = await axios.post(backUrl + 'asset/updateAssetPhoto', formData, { headers });

            console.log(response.data);
            getAssetDetails(id);
        } catch (error) {
            console.log(error);
        }
    }

    const acceptAsset = async () => {
        try {
            const response = await axios.post(backUrl + 'asset/acceptAsset', {
                assetId: id,
                assignedTo: assetData.assignedTo,
                assetName: assetData.assetName,
                remarks: remarks,
            }, { headers });

            getAssetHistory(id);
            getAssetDetails(id);
        } catch (error) {
            console.log(error);
        }
    }

    const unAssignAsset = async () => {
        try {
            if (myData.id === assetData.assignedTo) {
                const response = await axios.post(backUrl + 'asset/unAssignAsset', {
                    assetId: id,
                    assignedTo: assetData.assignedTo,
                    assignedToName: assetData.assignedToName,
                    assetName: assetData.assetName,
                    remarks: remarksAsset,
                }, { headers });

                getAssetHistory(id);
                getAssetDetails(id);
            } else {
                const response = await axios.post(backUrl + 'asset/unAssignAssetForce', {
                    assetId: id,
                    assignedTo: assetData.assignedTo,
                    assignedToName: assetData.assignedToName,
                    assetName: assetData.assetName,
                    remarks: remarksAsset,
                }, { headers });

                getAssetHistory(id);
                getAssetDetails(id);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const sendOnMaintenance = async () => {
        try {
            const response = await axios.post(backUrl + 'asset/sendOnMaintenance', {
                assetId: id,
                assetName: assetData.assetName,
                remarks: remarksMaintenance,
            }, { headers });

            getAssetHistory(id);
            getAssetDetails(id);
        } catch (error) {
            console.log(error);
        }
    }

    const repairedAsset = async () => {
        try {
            const response = await axios.post(backUrl + 'asset/repairedAsset', {
                assetId: id,
                assetName: assetData.assetName,
            }, { headers });

            getAssetHistory(id);
            getAssetDetails(id);
        } catch (error) {
            console.log(error);
        }
    }

    const convertToIST = (utcTime) => {
        const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30 in milliseconds
        const istTime = new Date(utcTime.getTime() + istOffset);

        // Extract date, month, year, hours, and minutes
        const date = istTime.getUTCDate();
        const month = istTime.getUTCMonth() + 1; // Month is zero-based, so add 1
        const year = istTime.getUTCFullYear();
        const hours = istTime.getUTCHours();
        const minutes = istTime.getUTCMinutes();

        // Format the IST date and time with leading zeros
        const formattedDate = `${date}/${month}/${year}`;
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return `${formattedDate} ${formattedTime}`;
    };

    useEffect(() => {
        chceckAdmin();
        getAssetDetails(id);
        getAssetHistory(id);
        getAllUsers();

        setCurrentUser(myData);
    }, []);
    console.log(currentUser);

    return (
        <>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-6 text-start'>
                        <button type="button" className='goBackButton' onClick={() => navigate(-1)}>Go Back</button>
                    </div>

                    {myData.role === 'MASTERADMIN' && assetData.assetState === 1 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Assign</button>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#sendOnMaintenance">Maintenance</button>
                        </div>
                    )}
                    {myData.role === 'MASTERADMIN' && assetData.assetState === 6 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Assign</button>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#sendOnMaintenance">Maintenance</button>
                        </div>
                    )}
                    {myData.role === 'MASTERADMIN' && assetData.assetState === 9 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Assign</button>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#sendOnMaintenance">Maintenance</button>
                        </div>
                    )}
                    {myData.role === 'SUPERADMIN' && assetData.assetState === 1 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Assign</button>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#sendOnMaintenance">Maintenance</button>
                        </div>
                    )}
                    {myData.role === 'SUPERADMIN' && assetData.assetState === 6 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Assign</button>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#sendOnMaintenance">Maintenance</button>
                        </div>
                    )}
                    {myData.role === 'SUPERADMIN' && assetData.assetState === 9 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Assign</button>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#sendOnMaintenance">Maintenance</button>
                        </div>
                    )}
                    {myData.role === 'ADMIN' && assetData.assetState === 1 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Assign</button>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#sendOnMaintenance">Maintenance</button>
                        </div>
                    )}
                    {myData.role === 'ADMIN' && assetData.assetState === 6 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Assign</button>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#sendOnMaintenance">Maintenance</button>
                        </div>
                    )}
                    {myData.role === 'ADMIN' && assetData.assetState === 9 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Assign</button>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#sendOnMaintenance">Maintenance</button>
                        </div>
                    )}
                    {assetData.assignedTo === myData._id && assetData.assetState === 2 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#acceptAsset">Accept</button>
                            <button type="button" className='assignButton'>Deny</button>
                        </div>
                    )}
                    {assetData.assignedTo === myData._id && assetData.assetState === 4 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#unassignAsset">Unassign</button>
                        </div>
                    )}
                    {assetData.assignedTo !== myData._id && myData.role === 'MASTERADMIN' && assetData.assetState === 4 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#unassignAsset">Unassign</button>
                        </div>
                    )}
                    {assetData.assignedTo !== myData._id && myData.role === 'SUPERADMIN' && assetData.assetState === 4 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#unassignAsset">Unassign</button>
                        </div>
                    )}
                    {assetData.assignedTo !== myData._id && myData.role === 'ADMIN' && assetData.assetState === 4 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#unassignAsset">Unassign</button>
                        </div>
                    )}
                    {assetData.assignedTo !== myData._id && myData.role === 'MASTERADMIN' && assetData.assetState === 2 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#unassignAsset">Unassign</button>
                        </div>
                    )}
                    {assetData.assignedTo !== myData._id && myData.role === 'SUPERADMIN' && assetData.assetState === 2 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#unassignAsset">Unassign</button>
                        </div>
                    )}
                    {assetData.assignedTo !== myData._id && myData.role === 'ADMIN' && assetData.assetState === 2 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' data-bs-toggle="modal" data-bs-target="#unassignAsset">Unassign</button>
                        </div>
                    )}
                    {myData.role === 'MASTERADMIN' && assetData.assetState === 8 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' onClick={repairedAsset}>Repaired</button>
                        </div>
                    )}
                    {myData.role === 'SUPERADMIN' && assetData.assetState === 8 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' onClick={repairedAsset}>Repaired</button>
                        </div>
                    )}
                    {myData.role === 'ADMIN' && assetData.assetState === 8 && (
                        <div className='col-lg-6 text-end'>
                            <button type="button" className='assignButton' onClick={repairedAsset}>Repaired</button>
                        </div>
                    )}
                </div>

                {/* Modal for Assigning */}
                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel"><b>Assigning {assetData.assetName} - {assetData.serialNumber}</b></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>Select to assign</p>
                                <select className="form-select" name="assetType" aria-label="Floating label select example"
                                    onChange={handleChangeOption}
                                    required>
                                    <option value="" selected>Select</option>
                                    {allUsers.map((user) =>
                                        <option value={user._id}>{user.fullname} ({user.empCode})</option>
                                    )}
                                </select>
                                <div className='userDataContainer mt-3'>
                                    {inventoryData.map((item) => (
                                        <div className='container invContainer' key={item._id}>
                                            <div className='row align-items-center'>
                                                <div className='col-6 py-2'>
                                                    {item.assetName} <br />
                                                    {item.brandName}
                                                </div>
                                                <div className='col-6 text-end'>
                                                    <button className='assetViewButton' onClick={() => window.open(`/viewasset/${item._id}`, '_blank')}>View</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">CANCEL</button>
                                {assignUser.length > 0 && (
                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={handleAssign}>ASSIGN</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal for Edit of Basic Details */}
                <div class="modal fade" id="basicDetailsEditModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="basicDetailsEditModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content custom_modal">
                            <div class="modal-header custom_footer-blue">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel"><b>Editing Basic Details</b></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <label htmlFor="asset_name" className="form-label">Asset Name</label>
                                <input type="text" className="form-control custom_modal_input blue_input" placeholder='Asset Name'
                                    name='asset_name'
                                    value={asset_name}
                                    onChange={(e) => setasset_name(e.target.value)}
                                />

                                <label htmlFor="serial_number" className="form-label">Serial Number</label>
                                <input type="text" className="form-control custom_modal_input blue_input" placeholder='Serial Number'
                                    name='serial_number'
                                    value={serial_number}
                                    onChange={(e) => setserial_number(e.target.value)}
                                />

                                <label htmlFor='asset_type' className='form-label'>Asset Type</label>
                                <select className='form-control custom_modal_input blue_input' name='asset_type' value={assignable_status}
                                    onChange={(e) => setassignable_status(e.target.value)}
                                >
                                    <option value="assignable">Assignable</option>
                                    <option value="non_assignable">Non-assignable</option>

                                </select>

                            </div>
                            <div class="modal_footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                {asset_name.trim().length > 0 && serial_number.trim().length > 0 && (
                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={handleBasicDetailsUpdate}>Submit</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal for Edit of Specifications */}
                <div class="modal modal-xl fade" id="specificationsEditModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="basicDetailsEditModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content custom_modal">
                            <div class="modal-header custom_footer-blue">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel"><b>Editing Specifications</b></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-sm-12'>
                                            <label htmlFor="brand_name" className="form-label">Brand Name</label>
                                            <input type="text" className="form-control custom_modal_input2" placeholder='Brand Name'
                                                name='brand_name'
                                                value={brand_name}
                                                onChange={(e) => setbrand_name(e.target.value)}
                                            />
                                        </div>

                                        <div className='col-lg-6 col-sm-12'>
                                            <label htmlFor="model_number" className="form-label">Model Number</label>
                                            <input type="text" className="form-control custom_modal_input2" placeholder='Model Number'
                                                name='model_number'
                                                value={model_number}
                                                onChange={(e) => setmodel_number(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {fields.length > 0 && (
                                        <>
                                            {fields.map((field, index) => (
                                                <div className='row' key={index}>
                                                    <div className='col-lg-4 col-sm-12 input_div'>
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type='text'
                                                                className='form-control blue_input'
                                                                placeholder='Property'
                                                                value={field.property}
                                                                onChange={(e) => handleSpecificationFieldChange(index, 'property', e.target.value)}
                                                            />
                                                            <label htmlFor={`property_${index}`} className='form-label'>Property</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 col-sm-12 input_div' key={index}>
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type='text'
                                                                className='form-control blue_input'
                                                                placeholder='Value'
                                                                value={field.value}
                                                                onChange={(e) => handleSpecificationFieldChange(index, 'value', e.target.value)}
                                                            />
                                                            <label htmlFor={`value_${index}`} className='form-label'>Value</label>
                                                        </div>
                                                    </div>
                                                    {index >= 0 && (
                                                        <>
                                                            <div className="d-grid gap-2 col-lg-2 col-sm-6">
                                                                <button
                                                                    className='btn btn-danger remove_field_btn'
                                                                    onClick={() => removeField(index)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                            <div className="d-grid gap-2 col-lg-2 col-sm-6">
                                                                <button className="btn btn-primary add_more_btn" type="button" onClick={addField}>Add More</button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>

                            </div>
                            <div class="modal_footer custom_footer_modal">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                {brand_name.trim().length > 0 && model_number.trim().length > 0 && (
                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={handleBasicDetailsUpdate2}>Submit</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal for edit of Document Details */}
                <div class="modal fade" id="documentDetailsEditModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="documentDetailsEditModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content custom_modal">
                            <div class="modal-header custom_footer-blue">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel"><b>Editing Document Details</b></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">

                                <label htmlFor="purchased_date" className="form-label">Purchased Date</label>
                                <input type="date" className="form-control custom_modal_input blue_input"
                                    name='purchased_date'
                                    value={purchased_date}
                                    onChange={(e) => setpurchased_date(e.target.value)}
                                />

                                <label htmlFor="warranty_expire" className="form-label">Warranty Expire</label>
                                <input type="date" className="form-control custom_modal_input blue_input"
                                    name='warranty_expire'
                                    value={warranty_expire}
                                    onChange={(e) => setwarranty_expire(e.target.value)}
                                />

                                {assetData.assetInvoice === "" ? (
                                    <>
                                        <label htmlFor='asset_invoice' className='form-label'>Invoice</label>
                                        <input type="file" className="form-control custom_modal_input blue_input"
                                            name='warranty_expire'
                                            onChange={(e) => setasset_invoice(e.target.files[0])}
                                        />
                                    </>
                                ) : (
                                    <div className='row custom_blue-row mb-2 mt-3'>
                                        <div className='col-6 fw-medium'>
                                            Invoice
                                        </div>
                                        <div className='col-6 text-end'>
                                            <span className='asset_document_download' onClick={() => handleDownloadClick(assetData.assetInvoice)}>Download Here</span>
                                            <i class="bi bi-trash mx-3 cross_icon" onClick={deleteAssetInvoicePhoto}></i>
                                        </div>
                                    </div>
                                )}

                                {assetData.otherDocAsset === "" ? (
                                    <>
                                        <label htmlFor='other_documents' className='form-label'>Other Documents</label>
                                        <input type="file" className="form-control custom_modal_input blue_input"
                                            name='other_documents'
                                            onChange={(e) => setassetother_document(e.target.files[0])}
                                        />
                                    </>
                                ) : (
                                    <div className='row custom_blue-row mb-2 mt-3'>
                                        <div className='col-6 fw-medium'>
                                            Other Documents
                                        </div>
                                        <div className='col-6 text-end'>
                                            <span className='asset_document_download' onClick={() => handleDownloadClick(assetData.otherDocAsset)}>Download Here</span>
                                            <i class="bi bi-trash mx-3 cross_icon" onClick={deleteOtherDocumentsPhoto}></i>
                                        </div>
                                    </div>
                                )}



                            </div>
                            <div class="modal_footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                {asset_name.trim().length > 0 && serial_number.trim().length > 0 && (
                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={submitOtherDocuments}>Submit</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal for Edit Asset Photo */}
                <div class="modal fade" id="editProfilePhoto" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="editProfilePhotoLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header custom_footer-blue">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel"><b>Editing Profile Photo</b></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">

                                {assetData.photoAsset ? (
                                    <img src={`${backUrl}${assetData.photoAsset}`} alt="" className="profile_image" />
                                ) : (
                                    <img src={noPicture} alt="" className="profile_image" />
                                )}

                                <div className='row mx-2 mt-4 justify-content-center'>
                                    <input type="file" className="form-control custom_modal_input blue_input"
                                        name='asset_photo'
                                        onChange={(e) => setasset_photo(e.target.files[0])}
                                    />
                                    <button className='edit_profile_button mx-1 mt-1' data-bs-dismiss="modal" onClick={uploadAssetPhoto}>Upload</button>
                                    <button className='edit_profile_cancel mx-1 mt-1' data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* For accepting asset */}
                <div class="modal fade" id="acceptAsset" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="acceptAssetLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header custom_footer-blue">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel"><b>Editing Profile Photo</b></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">



                                <div className='row mx-2 justify-content-center'>
                                    <label htmlFor="remarks" className="form-label">How is the condition ?</label>
                                    <input type="text" className="form-control custom_modal_input blue_input" placeholder='Remarks'
                                        name='remarks'
                                        value={remarks}
                                        onChange={(e) => setRemarks(e.target.value)}
                                    />
                                    <button className='edit_profile_button mx-1 mt-1' data-bs-dismiss="modal" onClick={acceptAsset}>Submit</button>
                                    <button className='edit_profile_cancel mx-1 mt-1' data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* For unassigning asset */}
                <div class="modal fade" id="unassignAsset" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="unassignAssetLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header custom_footer-blue">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel"><b>Unassigning {assetData.assetName}</b></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">



                                <div className='row mx-2 justify-content-center'>
                                    <label htmlFor="remarks" className="form-label">Reason for unassigning ?</label>
                                    <input type="text" className="form-control custom_modal_input blue_input" placeholder='Reason here'
                                        name='remarks'
                                        value={remarksAsset}
                                        onChange={(e) => setRemarksAsset(e.target.value)}
                                    />
                                    <button className='edit_profile_button mx-1 mt-1' data-bs-dismiss="modal" onClick={unAssignAsset}>Submit</button>
                                    <button className='edit_profile_cancel mx-1 mt-1' data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* For send asset on maintenance */}
                <div class="modal fade" id="sendOnMaintenance" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="sendOnMaintenanceLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header custom_footer-blue">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel"><b>Sending {assetData.assetName} for Maintenance</b></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">



                                <div className='row mx-2 justify-content-center'>
                                    <label htmlFor="remarks" className="form-label">Reason for Maintenance</label>
                                    <input type="text" className="form-control custom_modal_input blue_input" placeholder='Reason here'
                                        name='remarks'
                                        value={remarksMaintenance}
                                        onChange={(e) => setRemarksMaintenance(e.target.value)}
                                    />
                                    <button className='edit_profile_button mx-1 mt-1' data-bs-dismiss="modal" onClick={sendOnMaintenance}>Submit</button>
                                    <button className='edit_profile_cancel mx-1 mt-1' data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='row justify-content-center view_row'>

                    <div className='col-lg-6 col-sm-12 text-start'>
                        <div className='profile_card'>
                            {assetData.photoAsset ? (
                                <img src={`${backUrl}${assetData.photoAsset}`} alt="" className="profile_image ms-2" data-bs-toggle="modal" data-bs-target="#editProfilePhoto" />
                            ) : (
                                <img src={noPicture} alt="" className="profile_image ms-3 mb-1" data-bs-toggle="modal" data-bs-target="#editProfilePhoto" />
                            )}
                            {/* <div className='row justify-content-center mt-2'>
                                <button className='edit_profile_button' data-bs-toggle="modal" data-bs-target="#editProfilePhoto">Edit Photo</button>
                            </div> */}

                            <h4 className='text-start basic_details_heading'>BASIC DETAILS
                                {myData.role === 'MASTERADMIN' && (
                                    <span className='edit_icon' data-bs-toggle="modal" data-bs-target="#basicDetailsEditModal">
                                        <i class="bi bi-pencil-square"></i>
                                    </span>
                                )}
                                {myData.role === 'SUPERADMIN' && (
                                    <span className='edit_icon' data-bs-toggle="modal" data-bs-target="#basicDetailsEditModal">
                                        <i class="bi bi-pencil-square"></i>
                                    </span>
                                )}
                                {myData.role === 'ADMIN' && (
                                    <span className='edit_icon' data-bs-toggle="modal" data-bs-target="#basicDetailsEditModal">
                                        <i class="bi bi-pencil-square"></i>
                                    </span>
                                )}
                            </h4>

                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Asset Name
                                </div>
                                <div className='col-8'>
                                    {assetData.assetName || "None"}
                                </div>
                            </div>

                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Serial Number
                                </div>
                                <div className='col-8'>
                                    {assetData.serialNumber || "None"}
                                </div>
                            </div>


                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Asset Type
                                </div>
                                <div className='col-8'>
                                    {assetData.assetType || "None"}
                                </div>
                            </div>

                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Assigned to
                                </div>
                                <div className='col-8' style={{ color: '#4556ff' }}>
                                    {assetData.userDetails?.[0]?.fullname || "Not Assigned"}
                                </div>
                            </div>

                            <h4 className='text-start basic_details_heading mt-3'>SPECIFICATIONS
                                {myData.role === 'MASTERADMIN' && (
                                    <span className='edit_icon' data-bs-toggle="modal" data-bs-target="#specificationsEditModal">
                                        <i class="bi bi-pencil-square"></i>
                                    </span>
                                )}
                                {myData.role === 'SUPERADMIN' && (
                                    <span className='edit_icon' data-bs-toggle="modal" data-bs-target="#specificationsEditModal">
                                        <i class="bi bi-pencil-square"></i>
                                    </span>
                                )}
                                {myData.role === 'ADMIN' && (
                                    <span className='edit_icon' data-bs-toggle="modal" data-bs-target="#specificationsEditModal">
                                        <i class="bi bi-pencil-square"></i>
                                    </span>
                                )}
                            </h4>

                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Brand Name
                                </div>
                                <div className='col-8'>
                                    {assetData.brandName || "None"}
                                </div>
                            </div>

                            <div className='row text-start ms-2 mt-2'>
                                <div className='col-4 fw-medium'>
                                    Model
                                </div>
                                <div className='col-8'>
                                    {assetData.modelNumber || "None"}
                                </div>
                            </div>

                            {assetData.fields && assetData.fields.map((item) => (
                                <div className='row text-start ms-2 mt-2'>
                                    <div className='col-4 fw-medium'>
                                        {item.property}
                                    </div>
                                    <div className='col-8'>
                                        {item.value}
                                    </div>
                                </div>
                            ))}

                            {myData ? (
                                <>
                                    <h4 className='text-start basic_details_heading mt-3'>DOCUMENT DETAILS
                                        {myData.role === 'MASTERADMIN' && (
                                            <span className='edit_icon' data-bs-toggle="modal" data-bs-target="#documentDetailsEditModal">
                                                <i class="bi bi-pencil-square"></i>
                                            </span>
                                        )}
                                        {myData.role === 'SUPERADMIN' && (
                                            <span className='edit_icon' data-bs-toggle="modal" data-bs-target="#documentDetailsEditModal">
                                                <i class="bi bi-pencil-square"></i>
                                            </span>
                                        )}
                                        {myData.role === 'ADMIN' && (
                                            <span className='edit_icon' data-bs-toggle="modal" data-bs-target="#documentDetailsEditModal">
                                                <i class="bi bi-pencil-square"></i>
                                            </span>
                                        )}
                                    </h4>

                                    <div className='row text-start ms-2 mt-2'>
                                        <div className='col-4 fw-medium'>
                                            Purchased Date
                                        </div>
                                        <div className='col-8'>
                                            {assetData.purchasedDate || "None"}
                                        </div>
                                    </div>

                                    <div className='row text-start ms-2 mt-2'>
                                        <div className='col-4 fw-medium'>
                                            Warranty Expire
                                        </div>
                                        <div className='col-8'>
                                            {assetData.warrantyExpire || "None"}
                                        </div>
                                    </div>

                                    <div className='row text-start ms-2 mt-2'>
                                        <div className='col-4 fw-medium'>
                                            Invoice
                                        </div>
                                        <div className='col-8'>
                                            {assetData.assetInvoice === "" ? (
                                                <span>No Documents</span>
                                            ) : (
                                                <>
                                                    <span className='asset_document_download' onClick={() => handleDownloadClick(assetData.assetInvoice)}>Download Here</span>
                                                    <i class="bi bi-trash mx-3 cross_icon" onClick={deleteAssetInvoicePhoto}></i>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row text-start ms-2 mt-2'>
                                        <div className='col-4 fw-medium'>
                                            Other Documents
                                        </div>
                                        <div className='col-8'>
                                            {assetData.otherDocAsset === "" ? (
                                                <span>No Documents</span>
                                            ) : (
                                                <>
                                                    <span className='asset_document_download' onClick={() => handleDownloadClick(assetData.otherDocAsset)}>Download Here</span>
                                                    <i class="bi bi-trash mx-3 cross_icon" onClick={deleteOtherDocumentsPhoto}></i>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}

                        </div>
                    </div>

                    <div className='col-lg-6 col-sm-12'>

                        <div className='profile_card'>
                            <h4 className='text-start basic_details_heading'>Asset History</h4>
                            <div>
                                <ul className='activity_list'>
                                    {historyData.map((item) => (
                                        <li className='view_list mt-3' key={item._id}>
                                            <div className='activity_card'>
                                                <h6 className='fw-bold'>{convertToIST(new Date(item.createdAt))}</h6>
                                                {item.activityType === 1 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> added <span className='activityLink'>{item.assetInvovedName}</span> to inventory.</p>
                                                )}
                                                {item.activityType === 2 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> assigned <span className='activityLink'>{item.assetInvovedName}</span> to <span className='activityLink'>{item.userTargetedName}</span>.</p>
                                                )}
                                                {item.activityType === 3 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> denied to recieve <span className='activityLink'>{item.assetInvovedName}</span>.</p>
                                                )}
                                                {item.activityType === 4 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> recieved <span className='activityLink'>{item.assetInvovedName}</span>. [{item.remarks}]</p>
                                                )}
                                                {item.activityType === 5 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> unassigned <span className='activityLink'>{item.assetInvovedName}</span>. [{item.remarks}]</p>
                                                )}
                                                {item.activityType === 6 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> unassigned <span className='activityLink'>{item.assetInvovedName}</span>. [{item.remarks}]</p>
                                                )}
                                                {item.activityType === 8 && (
                                                    <p><span className='activityLink'>{item.userInvolvedName}</span> send <span className='activityLink'>{item.assetInvovedName}</span> for maintenance. [{item.remarks}]</p>
                                                )}
                                                {item.activityType === 9 && (
                                                    <p><span className='activityLink'>{item.assetInvovedName}</span> repaired and recieved by <span className='activityLink'>{item.userInvolvedName}</span>.</p>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewAsset