import React, { useEffect, useState } from 'react';
import './AllUsers.css';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { backUrl } from '../../constants';
import { useNavigate } from 'react-router-dom';

const token = localStorage.getItem('token');

const headers = {
  'Authorization': `Bearer ${token}`, // Set the appropriate content type
};

const AllUsers = () => {
  const navigate = useNavigate();

  const columns = [
    {
      accessorKey: 'empCode',
      header: "Emp Id",
      size: 10,
    },
    {
      accessorKey: "fullname",
      header: "Name",
      size: 200,
    },
    {
      accessorKey: "department",
      header: "Department",
      size: 100,
    },
    {
      accessorKey: "designation",
      header: "Designation",
      size: 200,
    },
    {
      header: "Action", // Column header for the action buttons
      Cell: ({ row }) => (
        <Button style={{ backgroundColor: "#012970", color: "white" }} onClick={() => viewUser(row.original._id)}>View</Button>
      ),
      size: 10,
    },
  ];

  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedHeaders = ['Emp Id', 'Name', 'Email', 'Designation'];
  const selectedColumns = columns.filter(column => selectedHeaders.includes(column.header));

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.post(backUrl + "auth/getAllUsers", {}, {headers});

      setAllUsers(response.data.filter(item => item.role !== 'SUPERUSER'));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const viewUser = (id) => {
    navigate(`/viewuser/${id}`);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const filteredUsers = allUsers.map(asset => {
    const filteredData = {};
    selectedColumns.forEach(column => {
      filteredData[column.accessorKey] = asset[column.accessorKey];
    });
    return filteredData;
  });

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: selectedColumns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    const filteredRows = rows.map(row => {
      const filteredData = {};
      selectedColumns.forEach(column => {
        filteredData[column.accessorKey] = row.original[column.accessorKey];
      });
      return filteredData;
    });

    csvExporter.generateCsv(filteredRows);
  };

  const handleExportData = () => {
    csvExporter.generateCsv(filteredUsers);
  };


  return (
    <>
      <div className='container-fluid full_div'>
        <div className='row'>
          <h3 className='text-center h3_addAsset'>USERS</h3>

          {loading ? (
            <div className='row align-items-center justify-content-center'><div className='custom-loader col-6 align-self-center fw-bold mt-5'></div></div>
          ) : (
            <MaterialReactTable
              columns={columns}
              data={allUsers}
              enableRowSelection
              positionToolbarAlertBanner="bottom"
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                  <Button
                    style={{ backgroundColor: "#012970" }}
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export All Data
                  </Button>

                  <Button
                    style={{
                      backgroundColor: table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
                        ? "#012970"
                        : "#CCCCCC",
                    }}
                    disabled={
                      !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export Selected
                  </Button>
                </Box>
              )}
            />
          )}


        </div>
      </div>
    </>
  )
}

export default AllUsers